<template>
  <div class="mt-2">
    <b-tabs
      content-class="mt-3"
      align="center"
    >
      <b-tab
        v-for="(item, index) in markets"
        :key="index"
        :title="item.name"
        lazy
        @click.prevent="fetchVendors(item.id)"
      >
        <my-table
          title=""
          :rows="vendors"
          :columns="columns"
          :pagination="pagination"
          :is-loading="isLoading"
          :has-export="true"
          :exporting="exporting"
          @handleExport="handleExportVendors"
        >
          <template v-slot="{props}">
            <span v-if="props.column.field === 'client.name'">
              <img
                :src="props.row.client.logo"
                width="70px"
                :alt="props.row.client.name"
              >
            </span>
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click.prevent="handleEditVendor(props.row)"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteVendor(props.row)">
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
          </template>
        </my-table>

        <b-col cols="12 text-center">
          <b-button
            variant="danger"
            :to="{ name: 'dashboard' }"
            class="mr-1"
          >Back to Dashboard</b-button>
        </b-col>
      </b-tab>
    </b-tabs>
    <AddEditBranchModal
      :is-show="showAddNewBranchModal"
      :form="selectedVendor"
      :is-edit="true"
      @reset-modal="resetAddEditBranchModal"
      @refetch-branches="fetchVendors(selectedMarket)"
    />
  </div>
</template>

<script>
import axios from '@axios'
import { MyTable } from '@/components'
import AddEditBranchModal from '@/components/AddEditBranchModal.vue'

export default {
  components: {
    MyTable,
    AddEditBranchModal,
  },
  data() {
    return {
      selectedMarket: 0,
      markets: [],
      exporting: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
          thClass: 'align-middle text-left',
          tdClass: 'align-middle text-nowrap text-left',
          type: 'number',
        },
        {
          label: 'Logo',
          field: 'client.name',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
        },
        {
          label: 'Name',
          field: 'name',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
        },
        {
          label: 'State',
          field: 'state',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
        },
        {
          label: 'Branch',
          field: 'area',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
        },
        {
          label: 'Address',
          field: 'complete',
          thClass: 'align-middle',
          tdClass: 'align-middle',
        },
        {
          label: 'Phone',
          field: 'phone',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
          sortable: false,
        },
      ],
      vendors: [],
      isLoading: false,
      pagination: {
        enabled: true,
        mode: 'records',
        perPage: 5,
        perPageDropdown: [5, 10, 15],
      },
      showAddNewBranchModal: false,
      selectedVendor: {},
    }
  },
  mounted() {
    this.fetchMarkets()
    this.fetchVendors()
  },

  methods: {
    async fetchMarkets() {
      const response = await axios.get('/markets')
      if (response.status === 200) {
        this.markets = response.data
      }
    },
    async fetchVendors(type = 1) {
      this.selectedMarket = type
      this.vendors = []
      this.isLoading = true
      const response = await axios.get(`/client-branches?market_type=${type}`)
      if (response.status === 200) {
        this.vendors = response.data
      }
      this.isLoading = false
    },
    async handleExportVendors(type) {
      this.exporting = true
      const response = await axios.get(`/client-branches/export/branches?market_type=${this.selectedMarket}&file_type=${type}`, { responseType: 'blob' })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', `b2bpro-vendors-${new Date().getTime()}.${type}`)
      document.body.appendChild(fileLink)
      fileLink.click()
      this.exporting = false
    },
    handleEditVendor(row) {
      this.showAddNewBranchModal = true
      this.selectedVendor = {
        image_path: row.client.logo,
        client: row.client,
        branch: {
          client_id: row.client.id,
          id: row.id,
          address1: row.address1,
          address2: row.address2,
          address3: row.address3,
          unit: row.unit,
          postcode: row.postcode,
          state: row.state,
          country: row.country,
          area: row.area,
          phone: row.phone,
          name: row.name,
        },
      }
    },
    deleteVendor(row) {
      this.$swal({
        title: 'Are you sure to delete this branch?',
        text: row.name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await axios.delete(`/client-branches/${row.id}`)
          if (response.status === 200) {
            this.fetchVendors(this.selectedMarket)
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Successfully Deleted',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }
      })
    },
    resetAddEditBranchModal() {
      this.showAddNewBranchModal = false
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
<style scoped></style>
